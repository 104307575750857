import React from 'react'

const BoostIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M20.8111 3.82772C20.8042 3.79455 20.7881 3.76404 20.7644 3.73976C20.7408 3.71549 20.7108 3.69847 20.6778 3.6907C18.4817 3.15313 13.4084 5.06858 10.6569 7.8203C10.1665 8.30705 9.71941 8.83557 9.32056 9.39988C8.47246 9.32308 7.62436 9.38775 6.90226 9.70302C4.86319 10.6003 4.26992 12.9438 4.10434 13.9498C4.09537 14.005 4.0992 14.0614 4.11554 14.1148C4.13187 14.1682 4.16028 14.2171 4.19855 14.2578C4.23682 14.2984 4.28393 14.3297 4.33623 14.3492C4.38852 14.3687 4.4446 14.3759 4.50012 14.3702L7.77419 14.0105C7.77645 14.2576 7.79128 14.5045 7.81861 14.7501C7.8357 14.9199 7.91139 15.0784 8.03266 15.1984L9.30037 16.4671C9.42033 16.5886 9.57886 16.6643 9.74865 16.6813C9.99261 16.7086 10.2378 16.7234 10.4833 16.7258L10.1242 19.9997C10.1186 20.0552 10.1258 20.1113 10.1453 20.1635C10.1648 20.2158 10.196 20.2628 10.2366 20.3011C10.2772 20.3393 10.326 20.3677 10.3792 20.3841C10.4325 20.4005 10.4888 20.4043 10.5438 20.3954C11.5494 20.2338 13.8959 19.64 14.7872 17.5997C15.1022 16.8762 15.1668 16.0318 15.0941 15.1871C15.6596 14.7882 16.1892 14.3407 16.6768 13.8496C19.4384 11.1011 21.3402 6.13524 20.8111 3.82772ZM14.2222 10.2891C13.981 10.0477 13.8167 9.74022 13.7501 9.40542C13.6835 9.07062 13.7177 8.72358 13.8482 8.40819C13.9787 8.0928 14.1997 7.82323 14.4833 7.63357C14.7669 7.44391 15.1003 7.34268 15.4414 7.34268C15.7825 7.34268 16.1159 7.44391 16.3995 7.63357C16.6831 7.82323 16.9042 8.0928 17.0347 8.40819C17.1652 8.72358 17.1993 9.07062 17.1327 9.40542C17.0662 9.74022 16.9019 10.0477 16.6607 10.2891C16.5007 10.4496 16.3106 10.5769 16.1014 10.6637C15.8922 10.7506 15.6679 10.7953 15.4414 10.7953C15.2149 10.7953 14.9906 10.7506 14.7814 10.6637C14.5722 10.5769 14.3822 10.4496 14.2222 10.2891Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.81111 16.7998C5.43728 16.8457 5.08949 17.0152 4.82298 17.2813C4.16223 17.9443 4.09961 20.3998 4.09961 20.3998C4.09961 20.3998 6.55661 20.3372 7.21773 19.6757C7.48495 19.4096 7.65471 19.0612 7.69961 18.6868"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BoostIcon
