import React from 'react'

const CariIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.367 16.2001C16.5015 16.0658 16.6838 15.9903 16.874 15.9903C17.0641 15.9903 17.2464 16.0658 17.3809 16.2001L20.3386 19.1578C20.4091 19.2235 20.4656 19.3027 20.5048 19.3907C20.544 19.4787 20.5651 19.5737 20.5668 19.67C20.5685 19.7663 20.5508 19.862 20.5147 19.9514C20.4786 20.0407 20.4249 20.1218 20.3568 20.19C20.2887 20.2581 20.2075 20.3118 20.1182 20.3479C20.0289 20.384 19.9332 20.4017 19.8369 20.4C19.7405 20.3983 19.6455 20.3772 19.5575 20.338C19.4695 20.2988 19.3903 20.2422 19.3247 20.1718L16.367 17.2141C16.2326 17.0796 16.1572 16.8972 16.1572 16.7071C16.1572 16.517 16.2326 16.3347 16.367 16.2001Z"
        fill={props.fill || '#1F1F1F'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5666 4.8001C9.81617 4.8001 8.13744 5.49545 6.8997 6.73319C5.66196 7.97093 4.9666 9.64967 4.9666 11.4001C4.9666 13.1505 5.66196 14.8293 6.8997 16.067C8.13744 17.3047 9.81617 18.0001 11.5666 18.0001C13.317 18.0001 14.9958 17.3047 16.2335 16.067C17.4712 14.8293 18.1666 13.1505 18.1666 11.4001C18.1666 9.64967 17.4712 7.97093 16.2335 6.73319C14.9958 5.49545 13.317 4.8001 11.5666 4.8001ZM3.7666 11.4001C3.7666 7.0921 7.2586 3.6001 11.5666 3.6001C15.8746 3.6001 19.3666 7.0921 19.3666 11.4001C19.3666 15.7081 15.8746 19.2001 11.5666 19.2001C7.2586 19.2001 3.7666 15.7081 3.7666 11.4001Z"
        fill={props.fill || '#1F1F1F'}
      />
    </svg>
  )
}

export default CariIcon
