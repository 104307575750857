import { _delete, patch, post, put } from '@helpers/fetch-wrapper'
import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  BULK_CANDIDATE_APPLICATION_STATUS_URL,
  DELETE_COMMENTS_URL,
  JOB_DUPLICATE_EXPIRED_JOB_URL,
  JOB_POSTS_BYID_URL,
  POST_COMMENTS_URL,
} from '@constants/url'

export const duplicateExpiredJob = createAsyncThunk(
  'job-detail/duplicateExpiredJob',
  async (jobId: string, { rejectWithValue }) => {
    try {
      const res = await post(JOB_DUPLICATE_EXPIRED_JOB_URL(jobId), {})
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateJobDetail = createAsyncThunk(
  'job-detail/updateJobDetail',
  async (
    {
      jobId,
      isActive,
      disableComment,
    }: { jobId: string; isActive?: boolean; disableComment?: boolean },
    { rejectWithValue }
  ) => {
    try {
      const updateJobDetailData: {
        isActive?: boolean
        disableComment?: boolean
      } = {
        isActive,
        disableComment,
      }
      const res = await patch(JOB_POSTS_BYID_URL(jobId), updateJobDetailData)

      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const bulkUpdateApplicantStatus = createAsyncThunk(
  'job-detail/bulk-applicant-status',
  async (
    {
      jobId,
      status,
      applicationIds,
    }: {
      jobId: string
      status: string
      applicationIds: string[]
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await put(
        BULK_CANDIDATE_APPLICATION_STATUS_URL(jobId, status),
        { applicationIds }
      )

      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type DeleteCommentProps = {
  jobPostId: string
  commentId: string
}

export const deleteComment = createAsyncThunk(
  'job/deleteComment',
  async (data: DeleteCommentProps, { rejectWithValue }) => {
    try {
      const res = await _delete(
        DELETE_COMMENTS_URL(data.jobPostId, data.commentId)
      )
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type PostCommentProps = {
  jobPostId: string
  commentText: string
  parentCommentId?: string
}

export const postComment = createAsyncThunk(
  'job/postComment',
  async (data: PostCommentProps, { rejectWithValue }) => {
    try {
      const commentData: { text: string; parentCommentId?: string } = {
        text: data.commentText,
      }

      if (data.parentCommentId) {
        commentData.parentCommentId = data.parentCommentId
      }

      const res = await post(POST_COMMENTS_URL(data.jobPostId), commentData)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
