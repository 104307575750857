import cn from '@helpers/utils'
import { VariantProps, cva } from 'class-variance-authority'
import React from 'react'

const chipClassName = cva(
  ['text-xs font-semibold text-isinvited-0 px-2 py-1 w-fit h-fit'],
  {
    variants: {
      type: {
        Free: 'rounded-md bg-primary-gray',
        Basic: 'rounded-md bg-hired-1',
        Premium: 'rounded-md bg-sunshine-400',
        'Premium+': 'rounded-md bg-selected-for-interview-1',
      },
    },
  }
)

type Props = VariantProps<typeof chipClassName> & {
  className?: string
}

const Chip = (props: Props) => {
  const { type = 'Basic', className } = props
  return (
    <div className={cn(chipClassName({ type }), className)}>
      {type === 'Free' ? 'Gratis' : type}
    </div>
  )
}

export default Chip
