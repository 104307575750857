import React from 'react'

const ArrowRightBlueIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_789_1491)">
        <path
          d="M1.09375 4.99951L8.90625 4.99951"
          stroke="#4460AC"
          strokeWidth="0.9375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.26074 8.64551L8.90658 4.99968L5.26074 1.35384"
          stroke="#4460AC"
          strokeWidth="0.9375"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_789_1491">
          <rect
            width="8.75"
            height="8.75"
            fill="white"
            transform="translate(9.375 0.625) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowRightBlueIcon
